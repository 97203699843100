.RichEditor-editor {
  cursor: text; }
  .RichEditor-editor ul,
  .RichEditor-editor ol {
    line-height: 1.4rem !important; }

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px; }

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none; }

.RichEditor-controls {
  user-select: none; }

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0; }

.RichEditor-activeButton {
  color: #45a582 !important; }

.preview p {
  min-height: 1.4rem; }

.preview ul {
  list-style: initial; }

.preview ul,
.preview ol {
  list-style-position: inside;
  line-height: 1.4rem !important; }

.preview ul ul,
.preview ol ol {
  margin-left: 20px; }

.preview li {
  list-style-position: outside;
  max-width: 100%;
  line-height: 1.4rem !important;
  margin-left: 1.2rem; }

.ory-controls-mode-toggle-control-group {
  position: relative !important;
  display: flex !important;
  padding: 0; }
  .ory-controls-mode-toggle-control-group > div {
    flex-direction: row !important; }

.ory-controls-mode-toggle-button {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: .7em; }
  .ory-controls-mode-toggle-button button {
    box-shadow: none;
    background-color: transparent;
    color: #555555; }
  .ory-controls-mode-toggle-button .MuiFab-secondary-4 {
    color: #fff;
    background-color: #45a582; }

div.react-dropdown-tree-select div.dropdown div.dropdown-content ul.root li.node.tree label {
  padding: 5px; }
  div.react-dropdown-tree-select div.dropdown div.dropdown-content ul.root li.node.tree label input.checkbox-item {
    width: auto;
    margin-right: 5px; }

.ory-controls-trash {
  position: fixed;
  bottom: -64px;
  z-index: 100;
  left: 50%;
  transition: bottom 200ms ease;
  padding: 8px; }

.ory-controls-trash.ory-controls-trash-active {
  bottom: 16px; }

[class^="MuiDrawer-paperAnchorLeft"],
[class*=' MuiDrawer-paperAnchorLeft'],
.ory-toolbar-drawer > div {
  right: 0;
  left: auto; }

.ory-cell ul,
.ory-cell ol {
  max-width: 100%;
  list-style: initial;
  margin-left: auto;
  margin-right: auto; }

.ory-cell [data-slate-string] {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.ory-cell blockquote {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-left: 4px solid #45a582;
  padding: 10px; }

.ory-plugins-content-spacer {
  border: 0 !important; }

.richtext {
  min-height: 150px; }

.richtext ul,
.richtext ol {
  list-style: initial;
  list-style-position: outside;
  margin-left: 1.4rem;
  line-height: 1.4rem !important; }

.richtext ol,
.preview ol {
  list-style-type: decimal; }

.richtext td,
.preview td {
  border: 1px solid #333333;
  padding: 10px; }

.richtext thead,
.preview thead {
  font-weight: 600;
  color: #45a582; }
